@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&;display=swap'); 







*{

  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

:root
{
  --blue:#000204;
  --withe:#fff;
  --grey:#f5f5f5;
  --black1:#222;
  --black2:#999;

}

body
{
 min-height: 100vh;
 overflow-x: hidden; 
}
.container{
  position: relative;
  width: 100%;
}
.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: #000810;
  color: #f5f2f2;
}
.alert-success {
  background:#00000a ;
  color: #f6f1f1;
}
.alert-wrapper {
  position: fixed;
  top: 7rem;
  right: 2rem;
  display: inline-block;
  
}
.alert-danger {
  background: #000204;
  color: #fff;
}
/****************training-card****************/
#cardbody{
  background: #DFC2F2;
	background-image: linear-gradient( to right, #ffffb3,#ffe6e6);
	background-attachment: fixed;	
	background-size: cover;
  
  
}
.form-label {
  display: inline-block;
  width: 120px; /* Adjust the width to your preference */
  text-align: right;
  margin-right: 10px; /* Add some spacing between label and input */
}
.card.active  {
  background-color: lightblue; /* Change to the desired active background color */
  /* Add any other styles to indicate the active state */
}
#cardcontainer{
  box-shadow: 0 15px 30px 1px grey;
	background: rgba(255, 255, 255, 0.90);
	text-align: center;
	border-radius: 5px;
	overflow: hidden;
	margin: 5em auto;
	height: 350px;
	width: 1100px;	
}
.product-details {
	position: relative;
	text-align: left;
	overflow: hidden;
	padding: 30px;
	height: 100%;
	float: left;
	width: 40%;

}

#cardcontainer .product-details h1{
	font-family: 'Bebas Neue', cursive;
	display: inline-block;
	position: relative;
	font-size: 30px;
	color: #344055;
	margin: 0;
	
}
.insnum{
  color: #222;
  font-size: 15px;
}

#cardcontainer .product-details h1:before{
	position: absolute;
	content: '';
	right: 0%; 
	top: 0%;
	transform: translate(25px, -15px);
	font-family: 'Bree Serif', serif;
	display: inline-block;
	background: #ffe6e6;
	border-radius: 5px;
	font-size: 14px;
	padding: 5px;
	color: white;
	margin: 0;
	animation: chan-sh 6s ease infinite;

}



	


.hint-star {
	display: inline-block;
	margin-left: 0.5em;
	color: gold;
	width: 50%;
}


#cardcontainer .product-details > p {
font-family: 'EB Garamond', serif;
	text-align: center;
	font-size: 18px;
	color: #7d7d7d;
	
}
.control{
  position: absolute;
	bottom: 20%;
	left: 22.8%;
  margin-bottom: -60px;
  justify-content: space-between;
	
}
.btn-card{

	transform: translateY(0px);
	transition: 0.3s linear;
	background:  #809fff;
	border-radius: 5px;
  position: relative;
  overflow: hidden;
	cursor: pointer;
	outline: none;
	border: none;
	color: #eee;
  margin: 2px;
	
	
	
}

.btn-card:hover{transform: translateY(-6px);
	background: #00040b;}

.btn-card span {
	font-family: 'Farsan', cursive;
	transition: transform 0.3s;
	display: inline-block;
  padding: 10px 20px;
	font-size: 1.2em;
	margin:0;
	
}
.btn-card .price, .shopping-cart{
	background: #333;
	border: 0;
	margin: 0;
}

.btn-card .price {
	transform: translateX(-10%); padding-right: 15px;
}

.btn-card .shopping-cart {
	transform: translateX(-100%);
  position: absolute;
	background: #333;
	z-index: 1;
  left: 0;
  top: 0;
}

.btn-card .buy {z-index: 3; font-weight: bolder;}

.btn-card:hover .price {transform: translateX(-110%);}

.btn-card:hover .shopping-cart {transform: translateX(0%);}



.product-image {
	transition: all 0.3s ease-out;
	display: inline-block;
	position: relative;
	overflow: hidden;
	height: 100%;

	width: 45%;
	display: inline-block;
}

#cardcontainer img {width: 100%;height: 100%;}

.info {
    background: rgba(27, 26, 26, 0.9);
    font-family: 'Bree Serif', serif;
    transition: all 0.3s ease-out;
    transform: translateX(-100%);
    position: absolute;
    line-height: 1.8;
    text-align: left;
    font-size: 105%;
    cursor: no-drop;
    color: #FFF;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.info h2 {text-align: center ; color: #000608;}
.info ul li strong{color: #00070a;}
.product-image .info{transform: translateX(0);}

.info ul li{transition: 0.3s ease;}
.info ul li:hover{transform: translateX(95px) scale(1.3);}

.product-image:hover img {transition: all 0.3s ease-out;}
.product-image:hover img {transform: scale(1.2, 1.2);}




/******************end-training-card*********/




/***********************forms******************/
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}
.form input[type='file'],
.form input[type='username'],
.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}
.eventform{
  position:relative;
  display: grid;
  min-height: 100px;
  min-width: 100px;
  padding: 20px;
}
.internship{
  
  position:relative;
  display: grid;
  min-height: 500px;
  padding: 20px;
  
}
.eventform label{
  color:#000308;
  font-weight: bold;
  width: 130px;
  margin-left: -13px;
}
.eventform input{
  width: 600px;
  height: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid black;
}
.internshipBtn{
  
  background: var(--blue);
  color: var(--withe);
  border-radius: 6px;
  cursor: pointer;
  border-color: transparent;
 


  border-radius: 6px;
  border-color: transparent;
  
}


/*********************forms*********************/
/************search-box************/
.search-box{
  background: #f0f2f5;
  width: 250px;
  border-radius:20px ;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-right: 10%; 
}
.search-box img{
  width: 14px;
}
.search-box input{
  width: 100%;
  background:transparent ;
  padding: 8px;
  outline: none;
  border: 0;
}
/************search-box************/
/*****************welcome-animation*************/
.main-wavy{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -48px;
}
.wavy{
  
  position: relative;
  right: 20px;
 
}
.wavy span{
  position: relative;
  display: inline-block;
  font-size: 2em;
  text-transform: uppercase;
  animation: animate 1s ease-in-out infinite;
  animation-delay: calc(0.1s* var(--i)); 
  margin-right: 20px; 
  color: #f4f4f4; 

}
@keyframes animate{
  0% { transform: translateY(0px);}
  20% {transform: translateY(-20px);}
  40%,100%{transform: translateY(0px);}
}
.navbar-left {
  display: flex;
  align-items: center;
  background-color: #000204;
}


.logo2 img {
  width: 50px;
  margin-right: 15 px;
  margin-bottom: 10px;
  display: block;
  border-radius: 50%;  
}
/*****************welcome-animation*************/



/****************Login******************/

.login-container {
  width: 31.25em;
  background-color: #f8f8f8;
  padding: 1.4em 3.75em;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 0.7em;
  box-shadow: 0 1em 4em rgba(71, 50, 4, 0.3);
}
.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}
.form .form-group {
  margin: 1.2rem 0;
}

.btn {
  display: inline-block;
  background: var(--red);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.4rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}
.btn-login {
  background: rgb(0, 0, 14);
  color: #fff;
  
}
.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.text-login {
  color:  rgb(0, 0, 7);
}

/****************End-Login******************/

.container-trainings{
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  
}




.navigation{
  position: fixed;
  width: 300px;
  height: 100%;
  background: var(--blue);
  border-left: 10px solid var(--blue);
  transition: 0.5s;
  overflow: hidden;
}
.navigation.active{
  width: 80px;
}
.navigation ul{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-left: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.navigation ul li:hover,
.navigation ul li.hovered {
  background: var(--withe);
}

.navigation ul li:nth-child(1)
{margin-bottom: 40px;
pointer-events: none;} 
.navigation ul li a{
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--withe);

}
.navigation ul li:hover a,
.navigation ul li.hovered a  {
  color: var(--blue);
}
.navigation ul li a .icon{
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 70px;
  text-align: center;
}
.navigation ul li a img{
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
}


.navigation ul li a .icon ion-icon
{
  font-size: 1.75em;
}

.navigation ul li a .title{
  position: rlative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
  font-family: 'Courier New', Courier, monospace;
}
/* curve outside*/
.navigation ul li:hover a::before,
.navigation ul li.hovered a::before{
  content: '';
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--withe);
  pointer-events: none;
}

.navigation ul li:hover a::after,
.navigation ul li.hovered a::after{
  
  content: '';
  position: absolute;
  right: 0;
  bottom:-50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--withe);
  pointer-events: none;
}
/* curve outside*/

/*main*/
.main{
  position: absolute;
  width: calc(100% - 300px);
  left:300px;
  min-height: 100vh;
  transition: 0.5s;
}
.mainevent{
  position: absolute;
 
  left:300px;
  
  background:var(--withe);
  transition: 0.5s;
}
.detailsevent{
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-top: 10px;
}
.main.active{
  width: calc(100% - 80px);
  left:80px;
}
.topbar{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.toggle{
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  cursor: pointer;
}
.search{
  position: relative;
  width: 400px;
  margin:0 10px
}
.search label input{
  width: 100%;
  height: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid var(--black2);
}

.search label ion-icon{
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.2em;
}

.user{
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  font-size:2.1em ;
  color: var(--blue);
}


/*Cards*/
.cardBox{
  position: relative;
  width: 80%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(8,1fr);
  grid-gap:10px ;
}

.cardBox .card {
  position: relative;
  background: black;
  padding: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 7px 25px rgba(0,0,0,0.08);
}

.cardBox .card .numbers{
  position: relative;
  font-weight: 600;
  font-size: 0.8em;
  color: white;
}
.cardBox .card .cardName{
  color: var(--black2);
  font-size: 1.1em;
  margin-top: 5px;
}

.cardBox .card .iconBx{
  font-size: 3.5em;
  color:var(--black2) ;
}

.cardBox .card:hover{
  background: white;
}
.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx{
color: black;
}

/*input file*/
.custom-file-input-resume::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input-resume::before {
  content: 'ajouter votre fichier';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 3px;
  padding: 10px;
  margin-top: -10px;
  margin-left: -12px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  
}
.custom-file-input-resume::before {
  border-color: black;
}
.custom-file-input-resume::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* data-list*/

.details{
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  margin-top: 10px;
  
}
.detailsinscriptions{
  position: relative;
  width: 50%;
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  margin-top: 10px;
}
.tableinscription{
  position: relative;
  display: grid;
  background: var(--withe);
  box-shadow: 0 7px 25px rgba(0,0,0,0.08);
  border-radius: 20px;
}
.tableinscription th{
  padding: 2.2rem;
  margin-right: 0.5rem;
 
}
.tableinscription td{
  padding: 1.6rem;
  text-align: left;
  
}
.head{
  margin-left: 20px;
}


.table th,
.table td {
  padding: 0.6rem;
 
}

.table th {
  color:#00050e ;
}
.table{
  position: relative;
  display: grid;
  background: var(--withe);
  box-shadow: 0 7px 25px rgba(0,0,0,0.08);
  border-radius: 20px;
}

.table {
  
  position: relative;
  display: grid;
  background: var(--withe);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0,0,0,0.08);
  border-radius: 20px;
  grid-gap: 30px;
  
  
}
.cardHeader{
 display: contents;  

}
.tdphone{
  margin: 16px;
}
.Header{
  display: flex;
  justify-content: space-between;
  align-items:flex-start ; 
}
.Header h2 {
  font-weight: 600;
  color: #00040b;
}
.btn-inscription{
  position: relative;
  padding: 5px 10px;
  background: #287bff;
  text-decoration: none;
  color: #f0f2f5;
  border-radius: 6px;
  margin-left: 750px;
}
.btn{
  position: relative;
  padding: 5px 10px;
  background: #000205;
  text-decoration: none;
  color: #f0f2f5;
  border-radius: 6px;
}
.details table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  
}

.details table thead td{
  font-weight: 600;
  
}

.details  .table tr{
  color: var(--black1);
  border-bottom: 1px solid rgba(0,0,0,0.1);
  
}
.details .table tbody tr:last-child{
  border-bottom: none;
}
.details .table tbody tr:hover{
  background: var(--blue);
  color: var(--withe);
}

.details .table tr td {
  padding-right: 60px;
  margin: 40px;
  
}
.details .table tr th{
  padding: 40px;  
}
.details .table {
  text-align: center;
  padding: 20px;  
}

.td-1{
  text-align: start;
  margin-right: 10px;
}
.td-2{
 padding-right: -12px;
}
.td-3{
  text-align: center;
}
.td-4{
  text-align: center;
}
.td-5{
  text-align: center;
}
.td-6{
  text-align: center;
}
.td-7{
  text-align: center;
}


.status.comingsoon {
  padding: 2px 4px;
  background: #f9ca3f;
  color: var(--withe);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.status.finished {
  padding: 2px 4px;
  background: #f00;
  color: var(--withe);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.status.inprogress {
  padding: 2px 4px;
  background: #1795ce;
  color: var(--withe);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

/* Recent Customers*/

.recentCustomers{
  position: relative;
  display: grid;
  min-height: 500px;
  padding: 20px;
  background: var(--withe);
  box-shadow:0 7px 25px rgba(0,0,0,0.08) ;
  border-radius: 20px;
}

.recentCustomers .imgBx{
  position: relative;
  width:40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.recentCustomers .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit:cover ;
}
.recentCustomers table tr td{
  padding: 12px 10px;

}
.recentCustomers table tr td h4{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2em;
}
.recentCustomers table tr td span{
  font-size: 14px;
  color: var(--black2);
}
.recentCustomers table tr:hover{
  background: var(--blue);
  color: var(--withe);
}
.recentCustomers table tr:hover td h4 span{
  color: var(--withe);
}
/*Internships design*/
.internship{
  
  position:relative;
  display: grid;
  min-height: auto;
  padding: 20px;
  
}
.internship label{
  color:#00060e;
  font-weight: bold;
  width: 130px;
  margin-left: -13px;
}
.internship input{
  width: 600px;
  height: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid black;
}
.internshipBtn{
  
  background: var(--blue);
  color: var(--withe);
  border-radius: 6px;
  cursor: pointer;
  border-color: transparent;
 


  border-radius: 6px;
  border-color: transparent;
  
}
/*--------------------pagination-internship----------------*/
.heading{
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: #00070e;
}
.internship-tablecontainer{
  width: 100%;
  height: 100%;
  width: calc(100% - 300px);
  left:300px;
  min-height: 100vh;
  background:var(--withe);
  transition: 0.5s;
}
.container-table2{
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  margin-left: 10px;

}
.main-table2{
  position: absolute;
  width: calc(100% - 300px);
  left:300px;
  min-height: 100vh;
}
.main-table3{
  position: absolute;
  width: calc(100% - 300px);
  left:700px;
  min-height: 100vh;
  
}
.table2 {
  border-collapse: collapse;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  font-weight: 500;
}

tr {
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

tr:nth-child(1) {
  background-color: transparent;
  box-shadow:none;
}
td, th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
}
th{
  font-size: 0.9rem;
  margin: 2rem;
}
.userPic>img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 2px solid #a2a1a1;
}

.userDetails {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  width: 250px;
}
.userHeading{
  width: 250px;
  margin-left: 20px;
}
.userName {
  font-weight: 600;
}

.userEmail {
  font-size: 13px;
  color: #a2a1a1;
}
.usersocial
.userBirth,
.userPhone,
.userAddress,
.contact {
  width: 150px;
}


.userAction{
  width: 30px;
  cursor: pointer;;
}
.contact,
.userAction, .userPhone,
.userAddress {
  text-align: center;
}
.f-weight{
  font-weight: 400;
  font-size: 0.9rem;
}
.contactCTA{
  background-color: rgb(0, 0, 9);
  font: inherit;
  font-size: 0.8rem;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
}
.DeleteALL{
  background-color: rgb(249, 4, 4);
  font: inherit;
  font-size: 0.8rem;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  float: right;
  margin-right: 23px;
  margin-bottom: 8px;
}
.pagination{
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 950px;
}

.bapagination{
  position: relative;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 150px;
}
.paginationactualites{
  position: relative;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 150px;
}
.pageNumbers{
  display: flex;
  padding: 0 1rem;
}
.num{
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.num:hover, .numActive{
  font-weight: 600;
}
.numActive{
  background-color: rgb(0, 0, 7);
  color: white;
}
.arrows{
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.arrows:hover{
  transform: scale(1.3);
}
/********************scoail**************/
.social-links{
  display: flex;
}
.social-link{
  text-decoration: none;
    color:#00060c;
    margin: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 10px;
    position: relative;
    transition: 0.4s;

}
.social-link:hover{
  transform: rotate(45deg);

}
.social-link::before{
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #373737;
  border-radius: 10px;
  transition: opasity 0.2s;

}
.social-link:hover::before{
  opacity: 0.4;

}
.social-link:nth-child(1)::before {
  background: #0a66c2;
}
.social-link:nth-child(2)::before {
  background: #f80404;

}
.social-link:nth-child(3)::before {
  background: #0a66c2;
}
.social-link:nth-child(4)::before {
  background: #0a66c2;
}
.social-link:nth-child(5)::before {
  background: #0080ff;
}
.social-link:hover::before {
  transform: translate(-4px, -4px);
  filter: blur(10px);
}
.social-link i {
  font-size: 1.7em;
  transition: all 0.4s;
}
.social-link.link:hover i {
  color: #c8c8c8;
  transform: rotate(-45deg);
}
.social-link:hover i.youtube {
  background: #f80404;
}
.social-link:hover i.instagram {
  color: #c32aa3;
}

.social-link:hover i.twitter {
  color: #1da1f2;
}


.social-link:hover i.facebook {
  color:#0080ff;
}

.social-link:hover i.linkedin {
  color: #0a66ca;
}
/**********************social************/

/*--------------------pagination-internship----------------*/
/*responsive design*/
@media (max-width:991px) {
  .navigation{
    left:-300px
  }
  .navigation.active{
    width: 300px;
    left: 0;
  }
  .main{
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
  }
  .main.active{
    left:300px
  }
  .cardBox{
    grid-template-columns: repeat(2,1fr);
  }
  .form-group {
    margin-bottom: 10px;
  }
  .userTable {
    padding: 10px;
  }

  .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .contactCTA {
    font-size: 14px;
    padding: 8px 12px;
  }
  
}

@media (max-width:768px){
  .details{
    grid-template-columns: repeat(1,1fr);
  }
  .form-group {
    margin-bottom: 10px;
  }
  .recentOrders{
    overflow-x: auto;
  }
  .status.inprogress{
    white-space: nowwrap;
  }
  .userTable {
    padding: 10px;
  }

  .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .contactCTA {
    font-size: 14px;
    padding: 8px 12px;
  }
}
@media (max-width:480px){
  .cardBox{
    grid-template-columns: repeat(1,1fr);
  }
  .cardHeader{
    font-size: 20px;
  }
  .user{
    min-width: 40px;
    
  }
  .form-group {
    margin-bottom: 10px;
  }
  
  .navigation{
    width: 100%;
    left: -100%;
    z-index: 1000;
  }
  .navigation.active{
    width: 100%;
    left:0;
  }
  .toggle{
    z-index: 10001;

  }
  .main.active .toggle{
   position: fixed;
   right:0 ;
   left: initial;
    color: var(--withe);
  }
  .userTable {
    padding: 10px;
  }

  .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .contactCTA {
    font-size: 14px;
    padding: 8px 12px;
  }
}

